import $ from 'jquery';

$(document).ready(function() {
	
	$(document).on('click', 'a[href^="#"]', function (event) {
		event.preventDefault();
	
		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top -100
		}, 300);
	});
	
	$('input').each(function() {
		
		$(this).on('focus', function() {
		  $(this).parent().parent('.gfield').addClass('active');
		});
	
		$(this).on('blur', function() {
		  if ($(this).val().length == 0) {
			$(this).parent().parent('.gfield').removeClass('active');
		  }
		});
		
		if ($(this).val() != '') $(this).parent('.css').addClass('active');
	
	});
	
	$('textarea').each(function() {
	
		$(this).on('focus', function() {
		  $(this).parent().parent('.gfield').addClass('active');
		});
	
		$(this).on('blur', function() {
		  if ($(this).val().length == 0) {
			$(this).parent().parent('.gfield').removeClass('active');
		  }
		});
		
		if ($(this).val() != '') $(this).parent('.css').addClass('active');
	
	});
	
	$('select').each(function() {
		
			$(this).on('focus', function() {
			  $(this).parent().parent('.gfield').addClass('active');
			});
		
			$(this).on('blur', function() {
			  if ($(this).val().length == 0) {
				$(this).parent().parent('.gfield').removeClass('active');
			  }
			});
			
			if ($(this).val() != '') $(this).parent('.css').addClass('active');
		
		});
});