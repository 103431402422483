import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

 $('.slider_engagement').slick({
	arrows: true,
	dots: false,
	infinite: false,
	responsive: [
		{
		breakpoint: 480,
		settings: {
			arrows: true,
			dots: false
			}
		}
	]
 });
 
 $('.slider_ambassadeurs').slick({
	 arrows: true,
	 dots: false,
	 infinite: false,
	 slidesToShow: 4,
	 slidesToScroll: 4,
	 responsive: [
		 {
		 breakpoint: 480,
		 settings: {
			 arrows: true,
			 dots: false,
			 slidesToShow: 1,
			 slidesToScroll: 1
			 }
		 }
	 ]
  });

$('.slider_offres').slick({
  dots: false,
  infinite: true,
  focusOnSelect: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 2,
  responsive: [
	{
	  breakpoint: 1024,
	  settings: {
		dots: false,
		slidesToShow: 2,
		slidesToScroll: 2,
		infinite: true
	  }
	},
	{
	  breakpoint: 600,
	  settings: {
		dots: false,
		slidesToShow: 2,
		slidesToScroll: 2
	  }
	},
	{
	  breakpoint: 480,
	  settings: {
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1
	  }
	}
  ]
});

$('.slider_logos_fluvial').slick({
  dots: false,
  infinite: true,
  focusOnSelect: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 2,
  responsive: [
	{
	  breakpoint: 1024,
	  settings: {
		dots: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		infinite: true
	  }
	},
	{
	  breakpoint: 600,
	  settings: {
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 3
	  }
	},
	{
	  breakpoint: 480,
	  settings: {
		arrows: true,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1
	  }
	}
  ]
});

$('.slider_offres').slick({
  dots: false,
  infinite: false,
  focusOnSelect: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
	{
	  breakpoint: 1024,
	  settings: {
		dots: false,
		slidesToShow: 2,
		slidesToScroll: 2,
		infinite: true
	  }
	},
	{
	  breakpoint: 600,
	  settings: {
		dots: false,
		slidesToShow: 2,
		slidesToScroll: 2
	  }
	},
	{
	  breakpoint: 480,
	  settings: {
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1
	  }
	}
  ]
});

	//$(".slider_offres").not('.slick-initialized').slick()

	$(".slider_offres").slick('reinit');
	
});